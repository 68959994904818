div.mapboxgl-map a {
  border-bottom-width: 0px;
}

/* div.mapboxgl-ctrl-bottom-right {
  width: fit-content;
  z-index: 10 !important;
  bottom: 30px !important;
  left: unset !important;
  right: 120px !important;
  z-index: 10;
  display: none;
}

div.mapboxgl-ctrl-bottom-left {
  width: fit-content;
  z-index: 10 !important;
  bottom: 30px !important;
  left: unset !important;
  right: 120px !important;
  z-index: 10;
  display: none;
} */
