@import "../../style/variables.scss";

.wordCard {
  border-radius: $standard-border-radius;
  padding: 12px;
  margin: 5px 0px 0px 0px;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 15px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.wordCard .featureWord {
  font-weight: 700;
}

.orthography {
  font-family: Tahoma, Geneva, Verdana, sans-serif;
}
