@import "../../style/variables";

.map {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.mapContainer {
  height: 100%;
  width: 70%;

  @include media-mobile {
    height: 300px;
    width: 100%;
  }
}

.zoomToAusBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
  border-radius: 1px;
  z-index: 10;
}
