@import "../../style/variables";

.searchArea {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: $app-background-color;
  width: 30%;

  @include media-tablet {
    width: 37%;
  }

  @include media-mobile {
    height: 500px;
    width: 100%;
  }
}

.textWrapper {
  height: 50%;
  overflow: auto;
  background-color: $transparent-background;
  padding: 7px 20px;
  margin-top: 20px;

  @include media-tablet {
    height: 45%;
  }

  @include media-mobile {
    height: 58%;
  }
}

.searchAreaInner {
  height: calc(100% - 50px);
}

.searchAreaWithoutLogo {
  height: calc(100% - 60px);
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-small-mobile {
    padding: 20px 12px;
  }
}

.filtersArea {
  @include media-mobile {
    display: none;
  }
}

.logoArea {
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImg {
  height: 100%;
  width: auto;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: $grey;
}
