$ilf-blue: hsl(194, 100%, 47%);
$grey: hsl(0, 0%, 13%);
$background-color: rgb(137, 28, 85);
$red: rgb(226, 74, 33);
$app-background-color: rgb(249, 157, 28);
$ilf-lightgrey: hsl(0, 0%, 66%);
$color-black: hsl(0, 0%, 0%);
$color-white: hsl(0, 0%, 100%);
$color-light-blue: hsl(216, 100%, 94%);
$transparent-background: rgba(255, 255, 255, 0.3);

$standard-border-radius: 4px;

$highlight-color: hsl(0, 39%, 28%);

@mixin media-laptop {
  @media screen and (max-width: 1500px) {
    @content;
  }
}

@mixin media-small-desktop {
  @media screen and (max-width: 1350px) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (max-width: 1150px) {
    @content;
  }
}

@mixin media-mobile {
  @media screen and (max-width: 850px) {
    @content;
  }
}

@mixin media-small-mobile {
  @media screen and (max-width: 330px) {
    @content;
  }
}
