@import "../../style/variables";

.filterButtonArea {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filterButton {
  border: none;
  height: 32px;
  font-size: 14px;
  padding: 4px 21px 6px 21px;
  box-sizing: border-box;
  position: relative;
  font-weight: 700;
  display: inline-block;
  margin: 1%;
  width: 31.3%;
  border-radius: $standard-border-radius;

  &:focus {
    outline: 0;
  }
  @include media-mobile {
    font-size: 16px;
  }
}

.filterButton.active {
  background-color: rgba(255, 255, 255, 0.65);
  color: $grey;
  font-weight: 900;
  padding: 4px 13px 6px 29px;
}

.filterButton.inactive {
  background-color: $transparent-background;
  color: white;
  opacity: 0.8;
}

.tickWrapper {
  position: absolute;
  left: 12px;
  top: 8px;
}
