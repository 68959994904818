@import "style/variables";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

.App {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  width: 100%;
  height: 100vh;
  max-height: 650px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey;
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  flex-direction: row;

  @include media-mobile {
    flex-direction: column;
    height: auto;
    max-height: unset;
  }
}

.appHeader {
  background-color: $app-background-color;
  @include media-mobile {
    padding: 16px;
  }
  @include media-small-mobile {
    padding: 12px;
  }
}

.App h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 67px;
}

.App h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 30px;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
  color: $grey;

  @include media-mobile {
    font-size: 24px;
    text-align: left;
    padding: 24px 0px;
  }
}

.App h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
}

.App h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: left;
}

.App h5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 26px;
  margin: 20px 0px 12px 0px;
  text-align: left;

  @include media-mobile {
    font-size: 18px;
  }
}

.App p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: $grey;
  margin-block-start: 14px;
  margin-block-end: 14px;
}

.App button {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.App ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.App a {
  text-decoration: none;
  color: inherit;
  border-color: inherit;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.App img {
  background-color: transparent;
}

.App mark {
  padding: 0em;
}

.communityDetailsWrapper {
  background-color: $color-white;
  padding: 28px 16px 0px 16px;
}
